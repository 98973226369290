"use client";

import { type FC } from "react";
import { Button } from "~/components/ui/button";
import { type FileWithPreview, ImageSelect } from "~/components/ui/image-select";
import { useNewPostState } from "../hooks/useNewPostState";
interface NewPostImageSelectButtonProps {
  children?: React.ReactNode;
  className?: string;
}
export const NewPostImageSelectButton: FC<NewPostImageSelectButtonProps> = ({
  children,
  className
}) => {
  const {
    setSelectedFileList,
    setActiveDialogView,
    setDialogOpen
  } = useNewPostState();
  async function handleImageSelect(fileList: FileWithPreview[]) {
    setSelectedFileList(fileList);
    if (fileList.length > 0) {
      setDialogOpen(true);
      setActiveDialogView("image-editor");
    }
  }
  return <ImageSelect onChange={handleImageSelect} compress={true} className="h-auto w-auto" data-sentry-element="ImageSelect" data-sentry-component="NewPostImageSelectButton" data-sentry-source-file="new-post-image-select-button.tsx">
      <Button variant="ghost" className={className} title="Bilder hochladen" data-sentry-element="Button" data-sentry-source-file="new-post-image-select-button.tsx">
        {children}
      </Button>
    </ImageSelect>;
};