"use client";

import * as React from "react";
import { Button } from "~/components/ui/button";
import { Card, CardContent, CardFooter } from "~/components/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "~/components/ui/dialog";
import { HiPhoto, HiVideoCamera } from "react-icons/hi2";
import { NewPostProvider, useNewPostState } from "./hooks/useNewPostState";
import { NewPostDialogTextEditor } from "./components/new-post-dialog-text-editor";
import { NewPostDialogImageEditor } from "./components/new-post-dialog-image-editor";
import { NewPostImageSelectButton } from "./components/new-post-image-select-button";
import { NewPostVideoEmbedButton } from "./components/new-post-video-embed-button";
import { NewPostDialogVideoEditor } from "./components/new-post-dialog-video-editor";
import { type Organization } from "~/components/domain/organization/model/organization-model";
import { OrganizationLogo } from "../../organization/organization-logo";
interface FeedNewPostCardProps {
  organization: Organization;
}
const INTERNAL__FeedNewPostCard: React.FC<FeedNewPostCardProps> = ({
  organization
}) => {
  const {
    dialogOpen,
    setDialogOpen,
    activeDialogView,
    setActiveDialogView
  } = useNewPostState();
  return <>
      <Card className="w-full" data-sentry-element="Card" data-sentry-source-file="feed-new-post-card.tsx">
        <CardContent className="p-0 pt-4" data-sentry-element="CardContent" data-sentry-source-file="feed-new-post-card.tsx">
          <div className="flex flex-row items-start gap-3 p-4 pt-0 text-sm text-foreground/80">
            <OrganizationLogo organization={organization} size="md" className="h-10 w-10" data-sentry-element="OrganizationLogo" data-sentry-source-file="feed-new-post-card.tsx" />
            <Button variant="outline" size="sm" className="flex h-10 grow flex-row items-center justify-start gap-1 rounded-full text-sm font-semibold" title="Neuen Beitrag verfassen Button" onClick={() => {
            setActiveDialogView("post-editor");
            setDialogOpen(true);
          }} data-sentry-element="Button" data-sentry-source-file="feed-new-post-card.tsx">
              Neuen Beitrag verfassen
            </Button>
          </div>
          <div className="flex flex-row items-start justify-start gap-3 px-4 pt-0 text-sm text-foreground/80">
            <div className="w-10"></div>
            <NewPostImageSelectButton className="gap-2 rounded-full border-[0.5px] text-sm font-normal" data-sentry-element="NewPostImageSelectButton" data-sentry-source-file="feed-new-post-card.tsx">
              <HiPhoto className="h-5 w-5 text-muted-foreground" data-sentry-element="HiPhoto" data-sentry-source-file="feed-new-post-card.tsx" />
              <span className="hidden sm:inline">Bild hochladen</span>
              <span className="inline sm:hidden">Bild</span>
            </NewPostImageSelectButton>
            <NewPostVideoEmbedButton className="gap-2 rounded-full border-[0.5px] text-sm font-normal" data-sentry-element="NewPostVideoEmbedButton" data-sentry-source-file="feed-new-post-card.tsx">
              <HiVideoCamera className="h-5 w-5 text-muted-foreground" data-sentry-element="HiVideoCamera" data-sentry-source-file="feed-new-post-card.tsx" />
              <span className="hidden sm:inline">Video hochladen</span>
              <span className="inline sm:hidden">Video</span>
            </NewPostVideoEmbedButton>
          </div>
        </CardContent>
        <CardFooter className="flex justify-between" data-sentry-element="CardFooter" data-sentry-source-file="feed-new-post-card.tsx"></CardFooter>
      </Card>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen} data-sentry-element="Dialog" data-sentry-source-file="feed-new-post-card.tsx">
        <DialogContent className="rounded-xs w-content top-10 max-h-[80dvh] max-w-fit translate-y-0 p-0" data-sentry-element="DialogContent" data-sentry-source-file="feed-new-post-card.tsx">
          <DialogTitle className="sr-only" data-sentry-element="DialogTitle" data-sentry-source-file="feed-new-post-card.tsx">Neuen Beitrag verfassen</DialogTitle>
          <DialogDescription className="sr-only" data-sentry-element="DialogDescription" data-sentry-source-file="feed-new-post-card.tsx">
            Sie können einen neuen Beitrag für den ClimateHub verfassen.
          </DialogDescription>

          {activeDialogView === "post-editor" && <NewPostDialogTextEditor organization={organization} />}
          {activeDialogView === "image-editor" && <NewPostDialogImageEditor />}
          {activeDialogView === "video-editor" && <NewPostDialogVideoEditor />}
        </DialogContent>
      </Dialog>
    </>;
};
export const FeedNewPostCard = ({
  organization
}: FeedNewPostCardProps) => {
  return <NewPostProvider data-sentry-element="NewPostProvider" data-sentry-component="FeedNewPostCard" data-sentry-source-file="feed-new-post-card.tsx">
      <INTERNAL__FeedNewPostCard organization={organization} data-sentry-element="INTERNAL__FeedNewPostCard" data-sentry-source-file="feed-new-post-card.tsx" />
    </NewPostProvider>;
};