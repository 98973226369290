"use client";

import { useCallback, useEffect, useState } from "react";
import { api } from "~/trpc/react";
import { uploadToS3 } from "~/lib/storage/uploadToS3";
import { HiPhoto, HiVideoCamera } from "react-icons/hi2";
import { DialogFooter, DialogHeader } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { Editor } from "~/components/ui/editor";
import { NewPostImagePreview } from "./new-post-image-preview";
import { NewPostVideoPreview } from "./new-post-video-preview";
import { NewPostImageSelectButton } from "./new-post-image-select-button";
import { NewPostVideoEmbedButton } from "./new-post-video-embed-button";
import { useNewPostState } from "../hooks/useNewPostState";
import { type Organization } from "~/components/domain/organization/model/organization-model";
import { OrganizationLogo } from "~/components/domain/organization/organization-logo";
interface NewPostDialogTextEditorProps {
  organization: Organization;
}
export const NewPostDialogTextEditor = ({
  organization
}: NewPostDialogTextEditorProps) => {
  const {
    editorContent,
    setEditorContent,
    setDialogOpen,
    mediaType,
    setMediaType,
    loading,
    setLoading,
    selectedFileList,
    setSelectedFileList,
    videoUrl,
    setVideoUrl,
    setActiveDialogView
  } = useNewPostState();
  const [filesAreUploading, setFilesAreUploading] = useState(false);
  const utils = api.useUtils();
  const createPost = api.post.create.useMutation({
    onSuccess: async () => {
      await utils.post.invalidate();
      setDialogOpen(false);
      setEditorContent(null);
      setSelectedFileList([]);
      setMediaType("none");
      setActiveDialogView("post-editor");
      window.location.reload();
    }
  });
  const storeFile = api.files.createMany.useMutation({
    onSuccess: async ({
      fileList
    }) => {
      if (mediaType === "image") {
        createPost.mutate({
          type: mediaType,
          fileList: fileList.map((file, idx) => ({
            id: file.id,
            bucket: file.bucket,
            filename: file.filename,
            originalFilename: file.originalFilename,
            contentType: file.contentType,
            size: file.size,
            order: idx
          })),
          content: JSON.stringify(editorContent)
        });
      } else {
        createPost.mutate({
          type: "text",
          content: JSON.stringify(editorContent)
        });
      }
    }
  });
  const createListOfPresignedUrl = api.files.createListOfPresignedUrl.useMutation({
    onSuccess: async ({
      urlList
    }) => {
      const fileListWithPresignedUrl = urlList.flatMap(urlResponse => {
        const file = selectedFileList[Number(urlResponse.key)];
        if (!file) {
          return [];
        }
        return {
          ...urlResponse,
          file: file
        };
      });
      setFilesAreUploading(true);
      const uploadToS3Response = await Promise.all(
      // loop through the files
      fileListWithPresignedUrl.map(async ({
        url,
        file
      }) => {
        return uploadToS3({
          url
        }, file);
      }));
      setFilesAreUploading(false);
      if (uploadToS3Response.some(res => res.status !== 200)) {
        const errorMessage = "Beim Hochladen der Bilder ist ein Fehler aufgetreten. Bitte laden Sie die Seite und versuchen Sie es erneut.";
        console.error(errorMessage);
        alert(errorMessage);
        return;
      }
      storeFile.mutate({
        files: fileListWithPresignedUrl.map(({
          filename,
          file
        }) => {
          return {
            name: filename,
            originalName: file.name,
            type: file.type,
            size: file.size
          };
        })
      });
    }
  });
  const updateIsLoading = useCallback(() => {
    setLoading(createListOfPresignedUrl.isPending || createPost.isPending || filesAreUploading);
  }, [createListOfPresignedUrl, createPost, filesAreUploading, setLoading]);
  useEffect(() => {
    const isLoadingNow = createListOfPresignedUrl.isPending || createPost.isPending || filesAreUploading;
    if (loading && !isLoadingNow) {
      setTimeout(() => {
        updateIsLoading();
      }, 500); // debounce to avoid flickering
    }
    if (!loading && isLoadingNow) {
      updateIsLoading();
    }
  }, [createListOfPresignedUrl, createPost, filesAreUploading, loading, updateIsLoading]);
  async function handleSubmit() {
    if (mediaType === "image" && selectedFileList.length > 0) {
      createListOfPresignedUrl.mutate({
        files: selectedFileList.map((file, idx) => ({
          key: idx.toString(),
          name: file.name,
          type: file.type,
          size: file.size
        }))
      });
    } else if (mediaType === "video" && videoUrl) {
      createPost.mutate({
        type: "video",
        video: {
          url: videoUrl,
          provider: "youtube" // hardcoded for now, fix in future
        },
        content: JSON.stringify(editorContent)
      });
    } else {
      createPost.mutate({
        type: "text",
        content: JSON.stringify(editorContent)
      });
    }
  }
  function handleClear() {
    const answer = window.confirm("Sind Sie sich sicher, dass Sie den Beitrag verwerfen möchten?");
    if (!answer) {
      return;
    }
    setMediaType("none");
    setSelectedFileList([]);
    setVideoUrl(null);
    setEditorContent(null);
    setActiveDialogView("post-editor");
  }
  return <div className="max-w-screen relative w-screen sm:max-w-[650px] md:w-[650px]" data-sentry-component="NewPostDialogTextEditor" data-sentry-source-file="new-post-dialog-text-editor.tsx">
      <DialogHeader className="absolute left-0 right-0 top-0 flex h-20 flex-row items-center justify-start p-6" data-sentry-element="DialogHeader" data-sentry-source-file="new-post-dialog-text-editor.tsx">
        <div className="flex shrink-0 flex-row items-center gap-2">
          <OrganizationLogo organization={organization} size="md" data-sentry-element="OrganizationLogo" data-sentry-source-file="new-post-dialog-text-editor.tsx" />

          <div className="flex h-9 flex-col items-start justify-center overflow-y-visible">
            <div className="text-lg font-semibold">{organization.name}</div>
          </div>
        </div>
      </DialogHeader>
      <div className="my-14 max-h-[calc(80dvh-9rem)] min-h-[300px] overflow-y-scroll p-6">
        <div className="min-h-[300px]">
          <Editor className="" content={editorContent} onUpdate={setEditorContent} showBubbleMenu={true} allowImageUpload={false} data-sentry-element="Editor" data-sentry-source-file="new-post-dialog-text-editor.tsx" />
        </div>
        {mediaType === "image" && <NewPostImagePreview />}
        {mediaType === "video" && <NewPostVideoPreview />}
      </div>
      <DialogFooter className="absolute bottom-0 left-0 right-0 flex h-16 flex-row items-center justify-start border-t-[0.5px] bg-background p-6" data-sentry-element="DialogFooter" data-sentry-source-file="new-post-dialog-text-editor.tsx">
        <div className="flex grow-0 flex-row items-center justify-start gap-1">
          <NewPostImageSelectButton data-sentry-element="NewPostImageSelectButton" data-sentry-source-file="new-post-dialog-text-editor.tsx">
            <HiPhoto className="h-5 w-5 text-muted-foreground" data-sentry-element="HiPhoto" data-sentry-source-file="new-post-dialog-text-editor.tsx" />
          </NewPostImageSelectButton>
          <NewPostVideoEmbedButton data-sentry-element="NewPostVideoEmbedButton" data-sentry-source-file="new-post-dialog-text-editor.tsx">
            <HiVideoCamera className="h-5 w-5 text-muted-foreground" data-sentry-element="HiVideoCamera" data-sentry-source-file="new-post-dialog-text-editor.tsx" />
          </NewPostVideoEmbedButton>
        </div>
        <div className="grow"></div>
        <div className="flex grow-0 flex-row items-center justify-end gap-1">
          <Button type="button" size="sm" variant="ghost" className="w-24 rounded-full hover:border-[0.5px]" disabled={loading} onClick={handleClear} data-sentry-element="Button" data-sentry-source-file="new-post-dialog-text-editor.tsx">
            Verwerfen
          </Button>
          <Button type="button" size="sm" variant="ghost" className="w-36 rounded-full border-[0.5px] bg-[#FCFAF8]" disabled={!editorContent || loading} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="new-post-dialog-text-editor.tsx">
            {loading ? "Veröffentlichen..." : "Veröffentlichen"}
          </Button>
        </div>
      </DialogFooter>
    </div>;
};