"use client";

import { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { HiMiniPlayCircle } from "react-icons/hi2";
import { DialogFooter, DialogHeader } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { useNewPostState } from "../hooks/useNewPostState";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "~/components/ui/form";
import { Input } from "~/components/ui/input";
const formSchema = z.object({
  url: z.string().url("Bitte geben Sie eine gültige URL ein.")
});
export const NewPostDialogVideoEditor = () => {
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: ""
    }
  });
  const {
    videoUrl,
    setVideoUrl,
    setActiveDialogView,
    setMediaType
  } = useNewPostState();
  useEffect(() => {
    if (!videoUrl) {
      // nothing to do
      return;
    }
    const url = form.getValues("url");
    if (url) {
      // if there is already a selected video, we don't need to do anything
      return;
    }

    // if not, we selected set the current video url
    form.setValue("url", videoUrl);
    setSelectedVideoUrl(videoUrl);
  }, [videoUrl, form, setSelectedVideoUrl]);

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    const isValidUrl = ReactPlayer.canPlay(values.url);
    if (!isValidUrl) {
      form.setError("url", {
        type: "manual",
        message: "Diese URL wird nicht unterstützt. Bitte geben Sie eine URL von einer der unterstützten Plattformen (Youtube) ein. "
      });
      return;
    }
    setSelectedVideoUrl(values.url);
  }
  function handleCancel() {
    setActiveDialogView("post-editor");
  }
  function handleConfirm() {
    if (!selectedVideoUrl) {
      return;
    }
    setMediaType("video");
    setVideoUrl(selectedVideoUrl);
    setActiveDialogView("post-editor");
  }
  return <div className="w-content sm:max-w-[650px]" data-sentry-component="NewPostDialogVideoEditor" data-sentry-source-file="new-post-dialog-video-editor.tsx">
      <DialogHeader className="absolute left-0 right-0 top-0 flex h-20 flex-row items-center justify-start p-6" data-sentry-element="DialogHeader" data-sentry-source-file="new-post-dialog-video-editor.tsx">
        <div className="text-lg font-semibold">Video einbetten</div>
      </DialogHeader>
      <div className="my-14 grid max-h-[calc(90dvh-9rem)] min-h-[300px] w-screen grid-cols-1 items-start justify-between gap-2 overflow-y-scroll bg-[#FCFAF8] p-4 sm:w-[650px]">
        <div>
          <Form {...form} data-sentry-element="Form" data-sentry-source-file="new-post-dialog-video-editor.tsx">
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="relative mx-0 flex w-full max-w-none flex-row items-start justify-start gap-2">
                <FormField control={form.control} name="url" render={({
                field
              }) => <FormItem className="w-full space-y-2">
                      <FormLabel className="sr-only">Video Url</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ" className="h-12 w-full max-w-none grow overflow-x-scroll text-base" />
                      </FormControl>
                      <FormDescription>
                        Die URL eines Videos, das Sie einbetten möchten.
                        Unterstützte Plattformen: Youtube
                      </FormDescription>
                      <div className="min-h-12">
                        <FormMessage />
                      </div>
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="new-post-dialog-video-editor.tsx" />
                <Button type="submit" variant="outline" className="mt-2 h-12 w-40" data-sentry-element="Button" data-sentry-source-file="new-post-dialog-video-editor.tsx">
                  Einbetten
                </Button>
              </div>
              <div></div>
            </form>
          </Form>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-2">
          <div className="text-base font-semibold"> Vorschau</div>
          <div className="w-full overflow-hidden rounded-md">
            {selectedVideoUrl && <ReactPlayer url={selectedVideoUrl} controls={true} style={{
            maxWidth: "100%",
            width: "100%",
            height: "360px"
          }} />}
            {!selectedVideoUrl && <div className="pointer-events-none flex flex-col items-center justify-center bg-[#E6E6E6]" style={{
            maxWidth: "100%",
            width: "100%",
            height: "360px"
          }}>
                <HiMiniPlayCircle className="h-20 w-20 text-foreground/40" />
              </div>}
          </div>
        </div>
      </div>
      <DialogFooter className="absolute bottom-0 left-0 right-0 flex h-16 flex-row items-center justify-start border-t-[0.5px] bg-background p-6" data-sentry-element="DialogFooter" data-sentry-source-file="new-post-dialog-video-editor.tsx">
        <div className="grow-0"></div>
        <div className="grow"></div>
        <div className="flex grow-0 flex-row items-center justify-end gap-2">
          <Button type="button" size="sm" variant="ghost" className="w-24 rounded-full" onClick={handleCancel} data-sentry-element="Button" data-sentry-source-file="new-post-dialog-video-editor.tsx">
            Zurück
          </Button>
          <Button type="button" size="sm" variant="ghost" className="w-24 rounded-full border-[0.5px] bg-[#FCFAF8]" disabled={!selectedVideoUrl} onClick={handleConfirm} data-sentry-element="Button" data-sentry-source-file="new-post-dialog-video-editor.tsx">
            Weiter
          </Button>
        </div>
      </DialogFooter>
    </div>;
};