/* eslint-disable @next/next/no-img-element */

"use client";

import React, { useEffect, useState, type FC, type SyntheticEvent } from "react";
import { cn } from "~/lib/utils";
import ReactCrop, { centerCrop, makeAspectCrop, type Crop, type PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button } from "~/components/ui/button";
import { CropIcon } from "lucide-react";
import { type FileWithPreview } from "./image-select";
interface ImageCropperProps {
  selectedFile: FileWithPreview;
  setSelectedFile: (file: FileWithPreview) => void;
  aspectRatio?: number;
}
export const ImageCropperWithButton: FC<ImageCropperProps> = ({
  selectedFile,
  setSelectedFile,
  aspectRatio
}) => {
  const imgRef = React.useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = React.useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = React.useState<string>("");
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [orientation, setOrientation] = useState<"portrait" | "landscape">("portrait");
  const [editMode, setEditMode] = useState<"none" | "crop">("none");
  useEffect(() => {
    setEditMode("none");
  }, [selectedFile]);
  function onImageLoad(e: SyntheticEvent<HTMLImageElement>) {
    const {
      width,
      height
    } = e.currentTarget;
    setWidth(width);
    setHeight(height);
    if (width > height) {
      setOrientation("landscape");
    } else {
      setOrientation("portrait");
    }
  }
  function setInitialCropWindow(height: number, width: number) {
    const aspect = aspectRatio ?? width / height;
    setCrop(centerAspectCrop(width * 0.9, height * 0.9, aspect));
  }
  function onCropComplete(crop: PixelCrop) {
    if (imgRef.current && crop.width && crop.height) {
      const croppedImageUrl = getCroppedImg(imgRef.current, crop);
      setCroppedImageUrl(croppedImageUrl);
    }
  }
  function getCroppedImg(image: HTMLImageElement, crop: PixelCrop): string {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * scaleX, crop.height * scaleY);
    }
    return canvas.toDataURL("image/png", 1.0);
  }
  async function imageUrlToFile(imageUrl: string): Promise<FileWithPreview> {
    return new Promise(resolve => {
      fetch(imageUrl).then(res => {
        res.blob().then(blob => {
          // please change the file.extension with something more meaningful
          // or create a utility function to parse from URL
          const filename = selectedFile.name.split(".")[0] + ".png";
          const file = new File([blob], filename, {
            type: blob.type
          });
          const fileWithPreview = Object.assign(file, {
            preview: URL.createObjectURL(file),
            path: selectedFile.path
          });
          resolve(fileWithPreview);
        }).catch(error => {
          //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          throw new Error(error);
        });
      }).catch(error => {
        //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(error);
      });
    });
  }
  async function onCrop() {
    try {
      // setCroppedImage(croppedImageUrl);
      const file = await imageUrlToFile(croppedImageUrl);
      setSelectedFile(file);
    } catch (error) {
      console.log(error);
      alert("Something went wrong!");
    }
  }
  return <div className="size-full" data-sentry-component="ImageCropperWithButton" data-sentry-source-file="image-cropper-with-button.tsx">
      <div className="mb-1 flex h-[calc(100%-6rem)] w-full flex-col items-center justify-center">
        {editMode === "none" && <div className="max-h-full rounded border">
            <img ref={imgRef} alt="Vorschau des bearbeiteten Bildes" src={selectedFile?.preview ?? ""} onLoad={onImageLoad} className={cn([orientation === "portrait" ? "h-[540px] w-auto" : "", "aspect-auto max-h-[540px] object-contain object-center"])} />
          </div>}
        {editMode === "crop" && <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={c => onCropComplete(c)} className="max-h-full rounded border" disabled={editMode != "crop"} aspect={aspectRatio}>
            <img ref={imgRef} alt="Vorschau des bearbeiteten Bildes" src={selectedFile?.preview ?? ""} onLoad={onImageLoad} className={cn([orientation === "portrait" ? "h-[540px] w-auto" : "", "aspect-auto max-h-[540px] object-contain object-center"])} />
          </ReactCrop>}
      </div>
      <div className="flex h-20 w-full flex-row items-center justify-center gap-2">
        {editMode === "none" && <Button variant="ghost" size="icon" onClick={() => {
        setEditMode("crop");
        setInitialCropWindow(height, width);
      }} className="rounded-full border-[0.5px] bg-background">
            <CropIcon className="size-4" />
            <span className="sr-only">Zuschneiden</span>
          </Button>}
        {editMode === "crop" && <>
            <Button variant="ghost" className="w-full rounded-full border-[0.5px]" onClick={() => setEditMode("none")}>
              Abbrechen
            </Button>
            <Button variant="ghost" size={"sm"} className="w-full rounded-full border-[0.5px] bg-background" onClick={onCrop}>
              Zuschneiden
            </Button>
          </>}
      </div>
    </div>;
};

// Helper function to center the crop
export function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number): Crop {
  return centerCrop(makeAspectCrop({
    unit: "%",
    width: 50,
    height: 50
  }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}