/* eslint-disable @next/next/no-img-element */

"use client";

import ReactPlayer from "react-player/youtube";
import { Button } from "~/components/ui/button";
import { HiMiniTrash } from "react-icons/hi2";
import { HiAdjustmentsVertical } from "react-icons/hi2";
import { useNewPostState } from "../hooks/useNewPostState";
export const NewPostVideoPreview = () => {
  const {
    mediaType,
    setMediaType,
    videoUrl,
    setVideoUrl,
    setActiveDialogView
  } = useNewPostState();
  if (mediaType !== "video") {
    return <></>;
  }
  if (!videoUrl) {
    return <></>;
  }
  return <div className="relative w-full pt-10" data-sentry-component="NewPostVideoPreview" data-sentry-source-file="new-post-video-preview.tsx">
      <div className="absolute left-0 right-2 top-12 flex flex-row items-center justify-end gap-2">
        <Button variant="ghost" size="icon" onClick={() => {
        setActiveDialogView("video-editor");
      }} className="group rounded-full border-[0.5px] bg-background shadow-sm hover:shadow-md" data-sentry-element="Button" data-sentry-source-file="new-post-video-preview.tsx">
          <HiAdjustmentsVertical className="size-3.5 group-hover:size-4" data-sentry-element="HiAdjustmentsVertical" data-sentry-source-file="new-post-video-preview.tsx" />
          <span className="sr-only">Bilder Anpassen</span>
        </Button>
        <Button variant="ghost" size="icon" onClick={() => {
        setMediaType("none");
        setVideoUrl(null);
      }} className="group rounded-full border-[0.5px] bg-background shadow-sm hover:shadow-md" data-sentry-element="Button" data-sentry-source-file="new-post-video-preview.tsx">
          <HiMiniTrash className="size-3.5 group-hover:size-4" data-sentry-element="HiMiniTrash" data-sentry-source-file="new-post-video-preview.tsx" />
          <span className="sr-only">Bilder entfernen</span>
        </Button>
      </div>

      <div className="w-full overflow-hidden rounded-md">
        {videoUrl && <ReactPlayer url={videoUrl} controls={true} style={{
        maxWidth: "100%",
        width: "100%",
        height: "360px"
      }} />}
      </div>
    </div>;
};