"use client";

import { Button } from "~/components/ui/button";
import { useNewPostState } from "../hooks/useNewPostState";
import { type FC } from "react";
interface NewPostVideoEmbedButtonProps {
  children?: React.ReactNode;
  className?: string;
}
export const NewPostVideoEmbedButton: FC<NewPostVideoEmbedButtonProps> = ({
  children,
  className
}) => {
  const {
    setActiveDialogView,
    setDialogOpen
  } = useNewPostState();
  async function handleClick() {
    setDialogOpen(true);
    setActiveDialogView("video-editor");
  }
  return <Button variant="ghost" className={className} title="Video Einbetten" onClick={handleClick} data-sentry-element="Button" data-sentry-component="NewPostVideoEmbedButton" data-sentry-source-file="new-post-video-embed-button.tsx">
      {children}
    </Button>;
};