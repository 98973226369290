/* eslint-disable @next/next/no-img-element */

"use client";

import { useEffect, useState } from "react";
import { cn } from "~/lib/utils";
import { DialogFooter, DialogHeader } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { ImageCropperWithButton } from "~/components/ui/image-cropper-with-button";
import { type FileWithPreview } from "~/components/ui/image-select";
import { useNewPostState } from "../hooks/useNewPostState";
export const NewPostDialogImageEditor = () => {
  const [selectedFile, setSelectedFile] = useState<FileWithPreview | null>(null);
  const {
    selectedFileList,
    setSelectedFileList,
    setActiveDialogView,
    setMediaType
  } = useNewPostState();
  useEffect(() => {
    if (selectedFile) {
      // if there is already a selected file, we don't need to do anything
      return;
    }

    // if not, we selected the first file in the list
    if (selectedFileList.length > 0 && selectedFileList[0]) {
      setSelectedFile(selectedFileList[0]);
    }
  }, [selectedFileList, selectedFile]);
  function handleFileHasBeenEdited(editedFile: FileWithPreview) {
    const newFileFileList = selectedFileList.map(file => {
      if (file.path === editedFile.path) {
        return editedFile;
      }
      return file;
    });
    setSelectedFile(editedFile);
    setSelectedFileList(newFileFileList);
  }
  function handleCancel() {
    setActiveDialogView("post-editor");
  }
  function handleConfirm() {
    setMediaType("image");
    setActiveDialogView("post-editor");
  }
  return <div className="w-content sm:max-w-[1128px]" data-sentry-component="NewPostDialogImageEditor" data-sentry-source-file="new-post-dialog-image-editor.tsx">
      <DialogHeader className="absolute left-0 right-0 top-0 flex h-20 flex-row items-center justify-start p-6" data-sentry-element="DialogHeader" data-sentry-source-file="new-post-dialog-image-editor.tsx">
        <div className="text-lg font-semibold">Editor</div>
      </DialogHeader>
      <div className="my-14 grid max-h-[calc(90dvh-9rem)] min-h-[300px] w-screen grid-cols-3 items-start justify-between gap-4 overflow-y-scroll bg-[#FCFAF8] p-4 lg:w-[1024px] xl:w-[1128px]">
        <div className="col-span-2 h-[640px] max-h-full grow">
          {selectedFile && <ImageCropperWithButton selectedFile={selectedFile} setSelectedFile={handleFileHasBeenEdited} />}
        </div>
        <div className="rounded-h-full col-span-1 flex h-full w-full flex-col items-start justify-start gap-2 overflow-x-hidden bg-background p-4">
          {selectedFileList.map(file => <Button key={file.path} variant="ghost" className={cn([selectedFile?.preview === file.preview ? "bg-muted" : "", "flex w-full max-w-full flex-row items-center justify-start gap-4 truncate rounded-sm py-7"])} onClick={() => setSelectedFile(file)}>
              <img key={file.name} src={file.preview} alt={file.name} className="aspect-video h-8 object-contain object-top" />
              <span className="truncate text-sm">{file.name}</span>
            </Button>)}
        </div>
      </div>
      <DialogFooter className="absolute bottom-0 left-0 right-0 flex h-16 flex-row items-center justify-start border-t-[0.5px] bg-background p-6" data-sentry-element="DialogFooter" data-sentry-source-file="new-post-dialog-image-editor.tsx">
        <div className="grow-0"></div>
        <div className="grow"></div>
        <div className="flex grow-0 flex-row items-center justify-end gap-2">
          <Button type="button" size="sm" variant="ghost" className="w-24 rounded-full" onClick={handleCancel} data-sentry-element="Button" data-sentry-source-file="new-post-dialog-image-editor.tsx">
            Zurück
          </Button>
          <Button type="button" size="sm" variant="ghost" className="w-24 rounded-full border-[0.5px] bg-[#FCFAF8]" onClick={handleConfirm} data-sentry-element="Button" data-sentry-source-file="new-post-dialog-image-editor.tsx">
            Weiter
          </Button>
        </div>
      </DialogFooter>
    </div>;
};