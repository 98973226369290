"use client";

import { type ReactNode, createContext, useContext, useState } from "react";
import { type FileWithPreview } from "~/components/ui/image-select";
type Props = {
  children?: ReactNode;
};
export type DialogViewType = "post-editor" | "image-editor" | "video-editor";
export type MediaType = "none" | "image" | "video";
type NewPostContextType = {
  editorContent: JSON | null;
  setEditorContent: (content: JSON | null) => void;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  activeDialogView: DialogViewType;
  setActiveDialogView: (dialogView: DialogViewType) => void;
  selectedFileList: FileWithPreview[];
  setSelectedFileList: (fileList: FileWithPreview[]) => void;
  videoUrl: string | null;
  setVideoUrl: (videoUrl: string | null) => void;
  mediaType: MediaType;
  setMediaType: (mediaType: MediaType) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};
const initialValue = {
  editorContent: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setEditorContent: () => {},
  dialogOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDialogOpen: () => {},
  activeDialogView: "post-editor" as DialogViewType,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveDialogView: () => {},
  selectedFileList: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedFileList: () => {},
  videoUrl: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setVideoUrl: () => {},
  mediaType: "none" as MediaType,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMediaType: () => {},
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {}
};
export const NewPostContext = createContext<NewPostContextType>(initialValue);
export const NewPostProvider = ({
  children
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(initialValue.dialogOpen);
  const [activeDialogView, setActiveDialogView] = useState<DialogViewType>(initialValue.activeDialogView);
  const [editorContent, setEditorContent] = useState<JSON | null>(initialValue.editorContent);
  const [mediaType, setMediaType] = useState<MediaType>(initialValue.mediaType);
  const [selectedFileList, setSelectedFileList] = useState<FileWithPreview[]>([]);
  const [videoUrl, setVideoUrl] = useState<string | null>(initialValue.videoUrl);
  const [loading, setLoading] = useState(initialValue.loading);
  return <NewPostContext.Provider value={{
    editorContent,
    setEditorContent,
    dialogOpen,
    setDialogOpen,
    activeDialogView,
    setActiveDialogView,
    selectedFileList,
    setSelectedFileList,
    videoUrl,
    setVideoUrl,
    mediaType,
    setMediaType,
    loading,
    setLoading
  }} data-sentry-element="unknown" data-sentry-component="NewPostProvider" data-sentry-source-file="useNewPostState.tsx">
      {children}
    </NewPostContext.Provider>;
};

// Define the useLayout hook
export const useNewPostState = (): NewPostContextType => {
  const context = useContext(NewPostContext);
  if (!context) {
    throw new Error("useNewPostState must be used within NewPostProvider");
  }
  return context;
};