/* eslint-disable @next/next/no-img-element */

"use client";

import { Button } from "~/components/ui/button";
import { useNewPostState } from "../hooks/useNewPostState";
import { HiMiniTrash } from "react-icons/hi2";
import { HiAdjustmentsVertical } from "react-icons/hi2";
export const NewPostImagePreview = () => {
  const {
    mediaType,
    selectedFileList,
    setMediaType,
    setSelectedFileList,
    setActiveDialogView
  } = useNewPostState();
  if (mediaType !== "image") {
    return <></>;
  }
  if (selectedFileList.length === 0) {
    return <></>;
  }
  return <div className="relative w-full pt-10" data-sentry-component="NewPostImagePreview" data-sentry-source-file="new-post-image-preview.tsx">
      <div className="absolute left-0 right-2 top-12 flex flex-row items-center justify-end gap-2">
        <Button variant="ghost" size="icon" onClick={() => {
        setActiveDialogView("image-editor");
      }} className="group rounded-full border-[0.5px] bg-background shadow-sm hover:shadow-md" data-sentry-element="Button" data-sentry-source-file="new-post-image-preview.tsx">
          <HiAdjustmentsVertical className="size-3.5 group-hover:size-4" data-sentry-element="HiAdjustmentsVertical" data-sentry-source-file="new-post-image-preview.tsx" />
          <span className="sr-only">Bilder Anpassen</span>
        </Button>
        <Button variant="ghost" size="icon" onClick={() => {
        setMediaType("none");
        setSelectedFileList([]);
      }} className="group rounded-full border-[0.5px] bg-background shadow-sm hover:shadow-md" data-sentry-element="Button" data-sentry-source-file="new-post-image-preview.tsx">
          <HiMiniTrash className="size-3.5 group-hover:size-4" data-sentry-element="HiMiniTrash" data-sentry-source-file="new-post-image-preview.tsx" />
          <span className="sr-only">Bilder entfernen</span>
        </Button>
      </div>

      {selectedFileList.length === 1 && selectedFileList[0] && <div className="w-full">
          <img src={selectedFileList[0].preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto max-h-[500px] w-min rounded-sm object-contain" />
        </div>}

      {selectedFileList.length === 2 && <div className="grid grid-cols-2 gap-1">
          {selectedFileList.map((file, idx) => <img key={idx} src={file.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />)}
        </div>}
      {selectedFileList.length === 3 && <div className="grid grid-cols-3 gap-1">
          <img src={selectedFileList[0]!.preview} alt="Vorschau des ausgewählten Bildes" className="col-span-2 mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
          <div className="col-span-1 flex flex-col items-center justify-center gap-1">
            <img src={selectedFileList[1]!.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
            <img src={selectedFileList[2]!.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
          </div>
        </div>}

      {selectedFileList.length === 4 && <div className="grid grid-cols-3 gap-1">
          <img src={selectedFileList[0]!.preview} alt="Vorschau des ausgewählten Bildes" className="col-span-2 mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
          <div className="col-span-1 flex h-min max-h-[500px] flex-col items-center justify-center gap-1 overflow-y-hidden">
            <img src={selectedFileList[1]!.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-1/3 w-min shrink grow rounded-sm object-cover object-center" />
            <img src={selectedFileList[2]!.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-1/3 w-min shrink grow rounded-sm object-cover object-center" />
            <img src={selectedFileList[3]!.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-1/3 w-min shrink grow rounded-sm object-cover object-center" />
          </div>
        </div>}

      {selectedFileList.length > 4 && <div className="grid grid-cols-3 gap-1">
          <img src={selectedFileList[0]!.preview} alt="Vorschau des ausgewählten Bildes" className="col-span-2 mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
          <div className="col-span-1 flex flex-col items-center justify-center gap-1">
            <img src={selectedFileList[1]!.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
            <img src={selectedFileList[2]!.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
            <div className="relative h-1/3 w-full">
              <div className="absolute inset-0 flex flex-row items-center justify-center rounded-sm bg-black/70 text-4xl font-semibold text-white">
                {`+ ${selectedFileList.length - 3} `}
              </div>
              <img width={700} height={700} src={selectedFileList[3]!.preview} alt="Vorschau des ausgewählten Bildes" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
            </div>
          </div>
        </div>}
    </div>;
};