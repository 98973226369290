"use client";

import { api } from "~/trpc/react";
import { type Feedback } from "./model/feedback-model";
export type ReviewType = "POSITIVE" | "NEGATIVE";
interface CreateFeedbackProps extends Pick<Feedback, "review" | "page"> {
  review: ReviewType;
  page: string;
  comment?: string;
}
interface UpdateFeedbackProps extends Pick<Feedback, "id" | "comment"> {
  id: string;
  comment: string;
}
export const useFeedback = () => {
  const createFeedbackMutation = api.feedback.createFeedback.useMutation();
  const updateFeedbackMutation = api.feedback.updateFeedback.useMutation();
  const createFeedback = async ({
    review,
    page,
    comment
  }: CreateFeedbackProps): Promise<Feedback | undefined> => {
    try {
      const result = await createFeedbackMutation.mutateAsync({
        review,
        page,
        comment
      });
      return result;
    } catch (error) {
      // TODO: log error
    }
  };
  const updateFeedback = async ({
    id,
    comment
  }: UpdateFeedbackProps): Promise<Feedback | undefined> => {
    try {
      const result = await updateFeedbackMutation.mutateAsync({
        id,
        comment
      });
      return result;
    } catch (error) {}
  };
  return {
    createFeedback,
    updateFeedback
  };
};